function getUrlVerify(url) {
    try {
        var domainUrl = window.location.href
        if (domainUrl.indexOf('ggcj.com') != -1) {
            domainUrl = 'ggcj.com'
        } else if (domainUrl.indexOf('ggcj.cn') != -1) {
            domainUrl = 'ggcj.cn'
        }
        if (url.indexOf('ggcj.com') == -1 && url.indexOf('ggcj.cn') == -1) {
            return url
        } else if (url.indexOf('ggcj.com') != -1) {
            return url.replace('ggcj.com', domainUrl)
        } else if (url.indexOf('ggcj.cn') != -1) {
            return url.replace('ggcj.cn', domainUrl)
        }
    } catch (err) {
        return url
    }
}
export default{
    getUrlVerify
}