import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:'/rightMain/rightMain/pwd',
    component: () => import('../views/Home.vue'),
    children:[
      {
        path:'/rightMain/rightMain/:name',
        component:() => import('../views/rightMain/rightMain.vue')
      },
      {
        path:'/myOrder/tjOrder',
        component:() => import('../views/myOrder/tjOrder.vue')
      },
      {
        path:'/billing/flower',
        component:() => import('../views/billing/flower.vue')
      },
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

export default router
