import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import {getRequest,postRequest} from './api/index'
import allFn from './plugin/allFn'

Vue.config.productionTip = false
Vue.use(ElementUI);
//在原型上添加axios请求方法
Vue.prototype.getRequest = getRequest
Vue.prototype.postRequest = postRequest
Vue.prototype.allFn = allFn

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
