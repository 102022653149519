import axios from 'axios'
// POST 方法封装  (参数处理)
export const postRequest = (url, params) => {
    return axios({
        method: 'post',
        url: url,
        data: params,
        transformRequest: [function (data) {
            let ret = ''
            for (let it in data) {
                ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
            }
            return ret
        }],
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
}
//  GET 方法封装
export const getRequest = (url) => {
    return axios({
        method: 'get',
        url: url
    });
}